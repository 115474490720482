import { Box, Card, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { Close, Delete, EditOutlined, SettingsOutlined } from "@mui/icons-material";
import colors from "../../../lib/colors";
import { Link } from "react-router-dom";
import { useState } from "react";
import { DateTime } from "luxon";
import { BigEvent } from "../../calendar/calendarTypes";
import { mobileModalStyle } from "../../../lib/styles";

interface EventViewProps {
    event: BigEvent,
    canDelete: boolean,
    handleCloseModal: () => void,
    handleDeleteCustomEvent: (event: BigEvent) => void,
    handleStartEdit: (event: BigEvent) => void,
}

export function MobileEventView({ event, canDelete, handleCloseModal, handleDeleteCustomEvent, handleStartEdit }: EventViewProps) {
    const theme = useTheme();
    const hasAssociatedProperty: boolean = !(event.resource.calendarEventPropertyInfo.propertyAddress == null || event.resource.calendarEventPropertyInfo.propertyAddress == "");
    const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState<null | HTMLElement>(null);
    const settingsMenuOpen = Boolean(settingsMenuAnchorEl);
    const lStart = DateTime.fromJSDate(event.start);
    const lEnd = DateTime.fromJSDate(event.end);

    const handleClickViewMenu = (event: React.MouseEvent<HTMLElement>) => {
        setSettingsMenuAnchorEl(event.currentTarget);
    };

    const handleCloseViewMenu = () => {
        setSettingsMenuAnchorEl(null);
    };

    return (
        <Card sx={mobileModalStyle}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(0.5)}>
                <Typography fontSize="28px" fontWeight={500} lineHeight="30px" color={colors.greyVioletDark} >{event.title}</Typography>
                <Box flex={1} />
                {canDelete &&
                    <IconButton sx={{ backgroundColor: "white" }} onClick={handleClickViewMenu}>
                        <SettingsOutlined />
                    </IconButton>
                }
                <IconButton onClick={handleCloseModal} sx={{ backgroundColor: "white" }}>
                    <Close />
                </IconButton>
            </Box>
            <Box marginTop={theme.spacing(4)} display="flex" flexDirection="column">
                <Box>
                    <Typography color="gray">Property</Typography>
                    {
                        hasAssociatedProperty ?
                            <Link style={{ fontSize: "16px", fontWeight: 500, color: colors.greyDarkest }}
                                to={"../Properties/" + event.resource.calendarEventPropertyInfo.propertyId}>
                                {event.resource.calendarEventPropertyInfo.propertyAddress}
                            </Link>
                            :
                            <Typography fontSize="16px" fontWeight={500} color={colors.greyDarkest} >None</Typography>
                    }
                </Box>
                <Box margin={`${theme.spacing(3)} 0px`} >
                    <Typography color="gray">Date</Typography>
                    <Typography fontSize="16px" fontWeight={500} color={colors.greyDarkest} >
                        {
                            event.allDay ? lStart.toLocaleString(DateTime.DATE_HUGE) : (
                                event.start.toDateString() == event.end.toDateString() ? lStart.toLocaleString(DateTime.DATE_MED) + ", " + lStart.toLocaleString(DateTime.TIME_SIMPLE) + " - " + lEnd.toLocaleString(DateTime.TIME_SIMPLE)
                                    : lStart.toLocaleString(DateTime.DATETIME_SHORT) + " - " + lEnd.toLocaleString(DateTime.DATETIME_SHORT)
                            )
                        }
                    </Typography>
                </Box>
                {
                    event.resource?.summary !== undefined && event.resource?.summary !== "" &&
                    <Box>
                        <Typography color="gray">Summary</Typography>
                        <Typography fontSize="16px" fontWeight={500} color={colors.greyDarkest} >{event.resource?.summary}</Typography>
                    </Box>
                }
            </Box>
            <Menu
                anchorEl={settingsMenuAnchorEl}
                open={settingsMenuOpen}
                onClose={() => handleCloseViewMenu()}
                MenuListProps={{
                    sx: {
                        borderRadius: theme.spacing(4)
                    }
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            borderRadius: theme.spacing(2),
                            background: colors.violetLightest
                        }
                    }
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={() => handleStartEdit(event)} disableRipple>
                    <Box display="flex" gap={theme.spacing(1.5)}>
                        <EditOutlined color="primary" />
                        <Typography color="primary" fontSize="16px" fontWeight={500} >Edit</Typography>
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => handleDeleteCustomEvent(event)} disableRipple>
                    <Box display="flex" gap={theme.spacing(1.5)}>
                        <Delete color="error" />
                        <Typography color="error" fontSize="16px" fontWeight={500} >Delete</Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </Card>
    );
}