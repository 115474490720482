import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { CloudUpload } from "@mui/icons-material";
import { Button, Card, TextField, Typography, getInitColorSchemeScript, styled } from "@mui/material";
import { useState } from "react";
import { ContainerClient } from '@azure/storage-blob';
import { Client } from "@microsoft/microsoft-graph-client";
import { msalConfig } from "../authConfig";
import { ORIGIN } from "../App";
import { USER_API_PATH } from "..";
import { parse } from "url";
import normalizeUrl from "normalize-url";
import { READ_SCOPE, USER_IMPERSONATION_SCOPE, WRITE_SCOPE } from "../lib/scopes";
import { PROPERTY_API_PATH, STRIPE_API_PATH } from "../lib/apiPaths";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const reductoSchema = {
    "type": "object",
    "properties": {
        "address": {
            "type": "object",
            "properties": {
                "premiseAddress": {
                    "type": "string",
                    "description": "The physical address of the leased property."
                },
                "unitNumber": {
                    "type": "string",
                    "description": "Unit number of apartment or suite, if applicable."
                },
                "city": {
                    "type": "string",
                    "description": "City in which the property is located."
                },
                "state": {
                    "type": "string",
                    "description": "State in which the property is located."
                },
                "zip": {
                    "type": "string",
                    "description": "Zip code for the property."
                },
                "county": {
                    "type": "string",
                    "description": "County in which the property is located."
                }
            },
            "required": [
                "premiseAddress",
                "city",
                "state",
                "zip"
            ]
        },
        "leaseInformation": {
            "type": "object",
            "properties": {
                "dateOfLease": {
                    "type": "string",
                    "description": "Date when the lease was executed."
                },
                "leaseStart": {
                    "type": "string",
                    "description": "Start date of the lease term."
                },
                "leaseEnd": {
                    "type": "string",
                    "description": "End date of the lease term."
                },
                "dueDate": {
                    "type": "number",
                    "description": "Day of the month rent is due."
                },
                "monthlyRent": {
                    "type": "number",
                    "description": "The amount of rent paid monthly."
                },
                "securityDeposit": {
                    "type": "number",
                    "description": "Amount paid as a security deposit to cover potential damages."
                },
                "petDeposit": {
                    "type": "number",
                    "description": "Deposit required for keeping pets, if applicable."
                }
            },
            "required": [
                "dateOfLease",
                "leaseStart",
                "leaseEnd",
                "monthlyRent"
            ]
        },
        "landlordDetails": {
            "type": "object",
            "properties": {
                "firstName": {
                    "type": "string",
                    "description": "Landlord's first name."
                },
                "lastName": {
                    "type": "string",
                    "description": "Landlord's last name."
                },
                "address": {
                    "type": "string",
                    "description": "Landlord's address."
                },
                "cellNumber": {
                    "type": "string",
                    "description": "Landlord's cell phone number."
                },
                "emailAddress": {
                    "type": "string",
                    "description": "Landlord's email address."
                }
            },
            "required": [
                "firstName",
                "lastName",
                "address"
            ]
        },
        "tenantDetails": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "firstName": {
                        "type": "string",
                        "description": "Tenant's first name."
                    },
                    "lastName": {
                        "type": "string",
                        "description": "Tenant's last name."
                    },
                    "email": {
                        "type": "string",
                        "description": "Tenant's email address."
                    },
                    "phoneNumber": {
                        "type": "string",
                        "description": "Tenant's phone number."
                    }
                },
                "required": [
                    "firstName",
                    "lastName"
                ]
            },
            "description": "List of tenants."
        },
        "occupants": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "firstName": {
                        "type": "string",
                        "description": "First name of an occupant."
                    },
                    "lastName": {
                        "type": "string",
                        "description": "Last name of an occupant."
                    }
                }
            },
            "description": "List of occupants other than the tenants."
        },
        // "premiseFeatures": {
        // "type": "object",
        // "properties": {
        //     "smokingPermitted": {
        //     "type": "boolean",
        //     "description": "Indicates whether smoking is permitted in the property."
        //     },
        //     "parking": {
        //     "type": "object",
        //     "properties": {
        //         "included": {
        //         "type": "boolean",
        //         "description": "Indicates if parking is included with the lease."
        //         },
        //         "numberOfSpaces": {
        //         "type": "integer",
        //         "description": "Number of parking spaces provided."
        //         },
        //         "spaceNumber": {
        //         "type": "string",
        //         "description": "Specific parking space number(s) allocated."
        //         },
        //         "garageDoorOpeners": {
        //         "type": "integer",
        //         "description": "Number of garage door openers provided."
        //         },
        //         "type": {
        //         "type": "string",
        //         "description": "Type of parking provided (e.g., covered, uncovered, garage)."
        //         },
        //         "monthlyFee": {
        //         "type": "number",
        //         "description": "Monthly parking fee, if applicable."
        //         },
        //         "feePaidBy": {
        //         "type": "string",
        //         "enum": [
        //             "landlord",
        //             "tenant"
        //         ],
        //         "description": "Indicates who pays the parking fee."
        //         }
        //     }
        //     },
        //     "storage": {
        //     "type": "object",
        //     "properties": {
        //         "included": {
        //         "type": "boolean",
        //         "description": "Indicates if storage space is included in the lease."
        //         },
        //         "spaceNumber": {
        //         "type": "string",
        //         "description": "Storage space number."
        //         },
        //         "location": {
        //         "type": "string",
        //         "description": "Location of the storage space within the property."
        //         },
        //         "monthlyFee": {
        //         "type": "number",
        //         "description": "Monthly storage fee."
        //         },
        //         "feePaidBy": {
        //         "type": "string",
        //         "enum": [
        //             "landlord",
        //             "tenant"
        //         ],
        //         "description": "Indicates who is responsible for the storage fee."
        //         }
        //     }
        //     },
        //     "appliancesIncluded": {
        //     "type": "object",
        //     "properties": {
        //         "refrigerator": {
        //         "type": "boolean",
        //         "description": "Includes a refrigerator."
        //         },
        //         "oven": {
        //         "type": "boolean",
        //         "description": "Includes an oven."
        //         },
        //         "microwave": {
        //         "type": "boolean",
        //         "description": "Includes a microwave."
        //         },
        //         "dishwasher": {
        //         "type": "boolean",
        //         "description": "Includes a dishwasher."
        //         },
        //         "washer": {
        //         "type": "boolean",
        //         "description": "Includes a washing machine."
        //         },
        //         "dryer": {
        //         "type": "boolean",
        //         "description": "Includes a dryer."
        //         },
        //         "windowAirConditions": {
        //         "type": "boolean",
        //         "description": "Includes window air conditioning units."
        //         }
        //     }
        //     },
        //     "latePenaltyPercentage": {
        //     "type": "number",
        //     "description": "Percentage of the rent charged as a penalty for late payment."
        //     },
        //     "securityDepositReturnDate": {
        //     "type": "string",
        //     "description": "Date by which the security deposit should be returned after lease termination."
        //     },
        //     "tenantResponsibilities": {
        //     "type": "object",
        //     "properties": {
        //         "electricity": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for the electricity."
        //         },
        //         "gas": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for the gas."
        //         },
        //         "waterSewer": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for water and sewer services."
        //         },
        //         "heatingFuel": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for heating fuel."
        //         },
        //         "refuseRemoval": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for refuse removal."
        //         },
        //         "HOADues": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for Homeowner Association dues."
        //         },
        //         "snowIceRemoval": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for snow and ice removal."
        //         },
        //         "lawnMowing": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for lawn mowing."
        //         },
        //         "landscapeMaintenance": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for landscape maintenance."
        //         },
        //         "scavengerService": {
        //         "type": "boolean",
        //         "description": "Tenant is responsible for scavenger services."
        //         },
        //         "other": {
        //         "type": "string",
        //         "description": "Other responsibilities not explicitly mentioned."
        //         }
        //     }
        //     },
        //     "petPolicy": {
        //     "type": "object",
        //     "properties": {
        //         "allowed": {
        //         "type": "boolean",
        //         "description": "Indicates if the tenant is allowed to have pets."
        //         },
        //         "details": {
        //         "type": "array",
        //         "items": {
        //             "type": "object",
        //             "properties": {
        //             "type": {
        //                 "type": "string",
        //                 "description": "Type of pet allowed."
        //             },
        //             "weight": {
        //                 "type": "number",
        //                 "description": "Maximum weight allowed for the pet."
        //             },
        //             "number": {
        //                 "type": "integer",
        //                 "description": "Number of pets allowed."
        //             }
        //             }
        //         }
        //         }
        //     }
        //     }
        // }
        // }
    }
};

export function TestHome() {
    const { instance, accounts } = useMsal();
    const [uploadSas, setUploadSas] = useState("No Write SAS yet");
    const [file, setFile] = useState<File>(new File([], "No File Selected"));
    const [fileURL, setfileURL] = useState("");
    const [blobName, setBlobName] = useState("");
    const [uploaded, setUploaded] = useState(false);
    const [reductoResponse, setReductoResponse] = useState("No Reducto Response Yet...");
    const user = accounts[0];

    async function FetchUploadSAS() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [USER_IMPERSONATION_SCOPE],
            account: user
        });
        const response = await fetch("https://propayafunctionsdev.azurewebsites.net/api/AcquireStorageSAS", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const body = await response.json();
        console.log(body);
        setUploadSas(body);
    }

    async function FileChosen(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files == null) return;
        setFile(event.target.files[0]);
    }

    async function UploadFile() {
        const containerClient = new ContainerClient(uploadSas);
        const blockBlobClient = containerClient.getBlockBlobClient("test/" + file.name);
        const uploadResponse = await blockBlobClient.uploadData(file);
        if (uploadResponse._response.status == 201) setUploaded(true);
        else return;
        setfileURL(blockBlobClient.url);
        setBlobName(blockBlobClient.name);
        console.log(uploadResponse);
        console.log(blockBlobClient);
    }

    async function TryReducto() {
        const readAccessToken = await instance.acquireTokenSilent({
            scopes: [USER_IMPERSONATION_SCOPE],
            account: user
        });
        const SASresponse = await fetch("https://propayafunctionsdev.azurewebsites.net/api/AcquireReadSAS?" + "blobName=" + blobName, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + readAccessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const readSAS: string = await SASresponse.json();
        console.log(readSAS);
        const requestBody = {
            document_url: readSAS,
            schema: reductoSchema
        }
        const reductoResponse = await fetch("https://v1.api.reducto.ai/extract", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                authorization: 'Bearer 4242ee92393c89ece12f0bd86b1e70c95bb770efcf5dbd0964f382909289350b357577c15260c897ec97c8ea567a328b'
            },
            body: JSON.stringify(requestBody)
        });
        const reductoResponseBody = await reductoResponse.json();
        console.log(reductoResponseBody);
        setReductoResponse(JSON.stringify(reductoResponseBody));
    }

    async function FetchGraphUser() {
        console.log(user);
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        console.log(accessToken);
        // const response = await fetch("https://graph.microsoft.com/v1.0/me", {
        //     method: "GET", // *GET, POST, PUT, DELETE, etc.
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization": "Bearer " + accessToken.accessToken
        //     },
        // });
        // const responseResponse = await response.json();
        // console.log(responseResponse);
    }

    async function UpdateGraphUser() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: ["https://graph.microsoft.com/User.ReadWrite"],
            account: user
        });
        const body = {
            "state": "NH"
        };
        console.log(accessToken);
        const response = await fetch("https://graph.microsoft.com/v1.0/me", {
            method: "PATCH", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken,
                "Host": "graph.microsoft.com"
            },
            body: JSON.stringify(body)
        });
        // const responseResponse = await response.json();
        console.log(response);
    }

    async function UpdateSubscriptionId() {
        const sessionId = "cs_test_a13ifEBs6WnnnEsDLjIGAIo0VlAN2JhSJnBLGcNq8TUmDYmZjreW6ItE2a";

        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });

        const response = await fetch(ORIGIN + USER_API_PATH + "/SubscriptionId?session_id=" + sessionId, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });

        const confirmation = await response.json();
        console.log(confirmation);
    }

    async function GetUpcomingInvoice(){
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });

        const response = await fetch(ORIGIN + STRIPE_API_PATH + "UpcomingInvoice?customerId=cus_QopUagd37feJlp", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        console.log(await response.json());
    }

    async function GetUser() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });

        const response = await fetch(ORIGIN + USER_API_PATH, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });

        console.log(await response.json())
    }

    async function FixLeases(){
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });

        const response = await fetch(ORIGIN + PROPERTY_API_PATH + "FixAllLeases", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
    }

    return (
        <Card sx={{ height: `calc(100vh - 64px)`, borderRadius: "32px", margin: "32px", padding: "32px" }} >
            <Typography>{uploadSas}</Typography>
            <Button onClick={() => FetchUploadSAS()}>fetch upload SAS</Button>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
            >
                Upload file
                <VisuallyHiddenInput id="fileHolder" type="file" onChange={FileChosen} />
            </Button>
            <Typography>{file.name}</Typography>
            {file.size != 0 && <Button onClick={() => UploadFile()}>submit</Button>}
            {uploaded && <Button onClick={() => TryReducto()}>Try Reducto</Button>}
            <Typography>{reductoResponse}</Typography>
            <Button variant="contained" onClick={FetchGraphUser} >Fetch Graph User</Button>
            <Button variant="contained" onClick={UpdateGraphUser} >Update Graph User</Button>
            <Button variant="contained" onClick={UpdateSubscriptionId} >Update Subscription Id</Button>
            <Button variant="contained" onClick={GetUser} >Get User</Button>
            <Button variant="contained" onClick={GetUpcomingInvoice} >Log upcoming invoice</Button>
            {/* <Button variant="contained" onClick={FixLeases} >Fix Leases</Button> */}

        </Card>
    );
}