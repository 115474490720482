import { BlockBlobClient, ContainerClient } from "@azure/storage-blob";
import { styled } from "@mui/material";
import { BigEvent } from "../components/calendar/calendarTypes";
var parser = require('parse-address');

export function getOrdinalSuffix(number: number) {
    let s = ["th", "st", "nd", "rd"];
    let v = number % 100;
    return (s[(v - 20) % 10] || s[v] || s[0]);
}

export function PreviewFile(file: File) {
    const link = document.createElement('a');
    const url = URL.createObjectURL(file);
    link.setAttribute('href', url);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function DownloadFile(file: File) {
    const link = document.createElement('a');
    const url = URL.createObjectURL(file);
    link.setAttribute('href', url);
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const USDollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const percentFormatter = new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function BigEventsSortByDate(a: BigEvent, b: BigEvent){
    const a_date = new Date(a.start);
    const b_date = new Date(b.start);
    if( a_date == b_date ) return 0;
    if( a_date < b_date ) return -1;
    return 1;
}

export interface Map {
    [key: string]: string | undefined
}

export interface ParsedAddress {
    number: string,
    prefix: string,
    street: string,
    type: string,
    city: string,
    state: string,
    zip: string
}

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export async function GetAvailableBlobName(fileName: string, containerClient: ContainerClient): Promise<BlockBlobClient> {
    const extensionIndex = fileName.lastIndexOf(".");
    const filePrefix = fileName.substring(0, extensionIndex);
    const fileExtension = fileName.substring(extensionIndex);
    let fileNameCount = 1;

    let blobName = filePrefix + fileExtension;
    let blockBlobClient = containerClient.getBlockBlobClient(blobName);

    while (await blockBlobClient.exists()) {
        blobName = filePrefix + "_" + fileNameCount.toString() + fileExtension;
        blockBlobClient = containerClient.getBlockBlobClient(blobName);
        fileNameCount++;
    }

    return blockBlobClient;
}

export function areAddressesEqual(address1: string, address2: string){
    const parsedAddress1: ParsedAddress = parser.parseLocation(address1);
    const parsedAddress2: ParsedAddress = parser.parseLocation(address2);

    return parsedAddress1.number == parsedAddress2.number && parsedAddress1.prefix == parsedAddress2.prefix && parsedAddress1.street == parsedAddress2.street && parsedAddress1.type == parsedAddress2.type;
}