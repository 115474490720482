export default {
    SchemesSurfaceContainerLow: "#F7F2FA",
    SchemesSurfaceContainerHigh: "#ECE6F0",
    SchemesSurface: "#FEF7FF",
    SchemesOnSurface: "#1D1B20",
    SchemesSecondaryContainer: "#E8DEF8",
    SchemesPrimaryContainer: "#EADDFF",
    SchemesPrimary: "#65558F",
    SchemesSurfaceDim: "#DED8E1",
    SchemesErrorContainer: "#F9DEDC",
    SchemesError: "#B3261E",
    PropayaViolet: "#8E68EC",
    PropayaVioletLight: "#BCA3F9",
    green: "#53B781",
    orange: "#FFAB48",
    red: "#D1623F",
    grey: "#9E9E9E",
    greyDark: "#7C7C7C",
    greyDarker: "#4E4E4E",
    greyDarkest: "#2D2D2D",
    greyLighter: "#E7E7E7",
    greyLightest: "#F4F4F4",
    greyViolet: "#817B90",
    greyVioletDark: "#534D61",
    violetLightest: "#F6F2FF",
    violetLighter: "#E9E0FF",
    violetDark: "#7251C3",
    yellowLight: "#ECF99E",
    yellowDarkest: "#555D20"
} as const;