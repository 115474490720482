import { LangPack } from './langpack';

// Implement the interface for English
const englishPack: LangPack = {
  // Nav drawer labels
  account: "Account",
  collapse: "Collapse",
  dashboard: "Dashboard",
  myproperties: "My Properties",
  calendar: "Calendar",
  // Dashboard labels
  incomingrent: "Incoming Rent",
  outgoingfees: "Outgoing Fees",
  occupancyrate: "Occupancy Rate",
  tendayforecast: "10-Day Forecast",
  noeventsplanned: "No events planned",
  // Calendar labels
  rentevents: "Rent Events",
  hoaevents: "HOA Events",
  noticeevents:"Notice Events",
  event: "Event",
  addevent: "Add Event",
  notice: "Notice",
  addnotice: "Add Notice",
  // Add property labels
  property: "Property",
  properties: "Properties",
  downloadpropertydata: "Download Property Data",
  addproperty: "Add Property",
  addnewproperty: "Add New Property",
  save: "Save",
  cancel: "Cancel",
  addtenant: "Add Tenant",
  addoccupant: "Add Occupant",
  nopropertiesyet: "No Properties Yet",
  leasepdf: "Lease PDF",
  propertyinfo: "Property Info",
  propertyinfo_address: "Address",
  propertyinfo_owner: "Owner",
  lease: "Lease",
  tenants: "Tenants",
  occupants: "Occupants",
  utilities: "Utilities",
  autopayenabled: "Auto Pay Enabled?",
  utility_electricity: "Electricity",
  utility_water: "Water",
  utility_gas: "Gas",
  utility_trash: "Trash",
  hoa: "HOA",
  // This is shit need to fix
  // Used to make "electricity note"
  note: " Note",
  company: "Company",
  phone: "Phone",
  website: "Website",
  association: "Association",
  monthlyfee: "Monthly Fee",
  annualfee: "Annual Fee",
  editnote: "Edit Note",
  hoainfo: "HOA Info",
  // Test labels
  test: "Test",
};

export default englishPack;