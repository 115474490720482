import { AppBar, Box, Button, Card, CircularProgress, Grid, IconButton, Modal, TextField, Toolbar, Tooltip, Typography, useTheme } from "@mui/material";
import colors from "../lib/colors";
import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { ORIGIN, STRIPE_MANAGEMENT_URL, STRIPE_PROMISE } from "../App";
import { IdTokenClaims } from "../authConfig";
import { Add, ArrowRight, ArrowRightAlt, Close, Edit, Help, Info, Logout, Remove, Save } from "@mui/icons-material";
import { READ_SCOPE, USER_READWRITE_MS_GRAPH_SCOPE, WRITE_SCOPE } from "../lib/scopes";
import { SidebarWidthContext } from "./Page";
import { CALENDAR_API_PATH, STRIPE_API_PATH, USER_GRAPH_URL } from "../lib/apiPaths";
import { StripeInvoice, StripeSubscription } from "../lib/stripeInterfaces";
import { modalStyle } from "../lib/styles";
import { USDollarFormatter } from "../lib/utils";
import { PropertyDTO } from "../lib/dataTransferObjects";
import { CacheLookupPolicy } from "@azure/msal-browser"
import { useNavigate } from "react-router-dom";


interface AccountFields {
    displayName: string,
    givenName: string,
    surname: string,
    email: string,
    city: string,
    state: string,
}

export function Account() {
    const theme = useTheme();
    const navigate = useNavigate()
    const navDrawerWidth = useContext(SidebarWidthContext);
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const [editing, setEditing] = useState(false);
    const [fields, setFields] = useState<AccountFields>({
        displayName: "",
        givenName: "",
        surname: "",
        email: "",
        city: "",
        state: "",
    });
    const [subscriptionMonthlyCost, setSubscriptionMonthlyCost] = useState(0);
    const [subscriptionScheme, setSubscriptionScheme] = useState("paid");
    const [propertyLimit, setPropertyLimit] = useState(1);
    const [manageLimitModalOpen, setManageLimitModalOpen] = useState(false);
    const [limitConfirmationModalOpen, setLimitConfirmationModalOpen] = useState(false);
    const [tooManyPropertiesModalOpen, setTooManyPropertiesModalOpen] = useState(false);
    const [newPropertyLimit, setNewPropertyLimit] = useState("1");
    const [invoicePreviewTotal, setInvoicePreviewTotal] = useState(0);
    const [invoicePreviewRecurringAmount, setInvoicePreviewRecurringAmount] = useState(0);
    const [propertyCount, setPropertyCount] = useState(0);
    const [loading, setLoading] = useState(false);

    async function GetAccountInfo() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const claims: IdTokenClaims = accessToken.idTokenClaims as IdTokenClaims;
        setFields({
            displayName: claims.name ?? "",
            email: user.username,
            givenName: claims.givenName ?? "",
            surname: claims.surname ?? "",
            city: claims.city ?? "",
            state: claims.state ?? "",
        });

        const invoiceResponse = await fetch(ORIGIN + STRIPE_API_PATH + "UpcomingInvoice", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });

        const minimalPropertyListResponse = await fetch(ORIGIN + CALENDAR_API_PATH + "MinimalPropertyList", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });

        console.log(accessToken);

        const minimalPropertyList: PropertyDTO[] = await minimalPropertyListResponse.json();
        setPropertyCount(minimalPropertyList.length);

        const invoice: StripeInvoice = await invoiceResponse.json();
        if (invoice.subscription === undefined) return;
        const subscription: StripeSubscription = invoice.subscription;

        console.log(subscription);

        setSubscriptionMonthlyCost(invoice.total / 100);
        setSubscriptionScheme(claims.subscriptionStatus ?? "");
        setPropertyLimit(subscription.items[0].quantity ?? 1);
        setNewPropertyLimit(subscription.items[0].quantity.toString());
    }

    useEffect(() => {
        GetAccountInfo();
    }, []);

    function handleAccountChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }

    function handleNewPropertyLimitChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if (/\D/.test(e.target.value)) return;
        if (newPropertyLimit == "0" && e.target.value.length >= 2) setNewPropertyLimit(e.target.value.substring(1));
        else if (e.target.value === "") setNewPropertyLimit("0");
        else if (e.target.value.length <= 3) setNewPropertyLimit(e.target.value);
    }

    async function SaveChanges() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [USER_READWRITE_MS_GRAPH_SCOPE],
            account: user
        });
        const body = {
            "displayName": fields.displayName,
            "givenName": fields.givenName,
            "surname": fields.surname
        };
        setEditing(false);
        const response = await fetch(USER_GRAPH_URL, {
            method: "PATCH", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken,
                "Host": "graph.microsoft.com"
            },
            body: JSON.stringify(body)
        });
    }

    async function ContinueLimitManagement() {
        if (parseInt(newPropertyLimit) < propertyCount) {
            setManageLimitModalOpen(false)
            setTooManyPropertiesModalOpen(true);
            return;
        }
        setLoading(true);
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });

        const invoicePreviewResponse = await fetch(ORIGIN + STRIPE_API_PATH + "InvoicePreview?newPropertyLimit=" + newPropertyLimit, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const invoicePreview: StripeInvoice = await invoicePreviewResponse.json();
        console.log((invoicePreview));
        setLoading(false);
        setInvoicePreviewTotal(Math.abs(invoicePreview.total) / 100);
        setManageLimitModalOpen(false)
        setLimitConfirmationModalOpen(true);
    }

    async function ConfirmUpdateLimit() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });

        setLoading(true);

        const updateSubscriptionResponse = await fetch(ORIGIN + STRIPE_API_PATH + "Subscription?newPropertyLimit=" + newPropertyLimit, {
            method: "PATCH", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        pollUntilUpdated();
    }

    async function pollUntilUpdated() {
        let accessToken;
        for (let i = 0; i < 40; i++) {
            accessToken = await instance.acquireTokenSilent({
                scopes: [READ_SCOPE],
                account: user,
                forceRefresh: true
            });
            // @ts-ignore
            if (accessToken.idTokenClaims.propertyLimit === newPropertyLimit) {
                console.log(accessToken.idTokenClaims);

                setLoading(false);
                window.location.reload();
                return;
            }
            else {
                console.log("Not yet...")
                console.log(accessToken.idTokenClaims);
            }
            await new Promise(resolve => setTimeout(resolve, 2000))
        }
        //error!!!!!!!!!
        window.location.reload();
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto", bgcolor: colors.SchemesSurfaceContainerLow, overflowX: "hidden", height: "100vh" }}>
            <Modal
                open={manageLimitModalOpen}
                onClose={() => setManageLimitModalOpen(false)}
            >
                <Card sx={{ ...modalStyle, width: 420 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" >
                        <Box width="100%" display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(0.5)}>
                            <Box flex={1} />
                            <IconButton onClick={() => setManageLimitModalOpen(false)} sx={{ backgroundColor: "white" }}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Typography fontSize="28px" fontWeight={500} lineHeight="30px" textAlign="center">Update Property Limit</Typography>
                        <Box margin={theme.spacing(1)} display="flex" flexDirection="column" alignItems="center">
                            <Typography fontWeight={400} fontSize="16px" color={colors.grey}>{"Current Limit: " + propertyLimit} </Typography>
                            <Typography fontWeight={400} fontSize="16px" color={colors.grey}>{USDollarFormatter.format(subscriptionMonthlyCost) + " / month"} </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <IconButton disabled={newPropertyLimit === "15"} onClick={() => {
                                let val = parseInt(newPropertyLimit)
                                val--;
                                setNewPropertyLimit(val.toString());
                            }}>
                                <Remove />
                            </IconButton>
                            <TextField
                                sx={{ margin: theme.spacing(2) }}
                                label="Property Limit"
                                name="newPropertyLimit"
                                error={parseInt(newPropertyLimit) < 15}
                                helperText={parseInt(newPropertyLimit) < 15 ? "Must be at least 15" : ""}
                                value={newPropertyLimit}
                                onChange={handleNewPropertyLimitChange}
                            />
                            <IconButton disabled={newPropertyLimit === "999"} onClick={() => {
                                let val = parseInt(newPropertyLimit)
                                val++;
                                setNewPropertyLimit(val.toString());
                            }}>
                                <Add />
                            </IconButton>
                        </Box>
                        <Box marginTop={theme.spacing(4)} gap={theme.spacing(1.5)} display="flex" flexDirection="row" justifyContent="center" alignItems={"center"}>
                            <Button variant="outlined" color="primary" onClick={() => setManageLimitModalOpen(false)}>Nevermind</Button>
                            <Button
                                disabled={newPropertyLimit == propertyLimit.toString() || parseInt(newPropertyLimit) < 15 || loading}
                                variant="contained"
                                color="primary"
                                onClick={() => ContinueLimitManagement()}
                            >
                                Continue
                            </Button>
                            {
                                loading &&
                                <CircularProgress size={24} sx={{
                                    position: 'absolute',
                                    left: '61%',
                                }} />
                            }
                        </Box>
                    </Box>
                </Card>
            </Modal>
            <Modal
                open={limitConfirmationModalOpen}
                onClose={() => {
                    if( !loading ) setLimitConfirmationModalOpen(false);
                }}
            >
                <Card sx={{ ...modalStyle, width: 420 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" >
                        <Box width="100%" display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(0.5)}>
                            <Box flex={1} />
                            {
                                !loading &&
                                <IconButton onClick={() => setLimitConfirmationModalOpen(false)} sx={{ backgroundColor: "white" }}>
                                    <Close />
                                </IconButton>
                            }
                        </Box>
                        <Typography fontSize="28px" fontWeight={500} lineHeight="30px" textAlign="center">Update Property Limit</Typography>
                        <Box margin={theme.spacing(1)} display="flex" flexDirection="column" alignItems="center">
                            <Typography fontWeight={400} fontSize="16px" color={colors.grey}>Property Limit: </Typography>
                            <Box display="flex" flexDirection="row" alignItems="center" marginBottom={theme.spacing(4)}>
                                <Typography>{propertyLimit}</Typography>
                                <ArrowRightAlt sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }} />
                                <Typography>{newPropertyLimit}</Typography>
                            </Box>
                            <Grid container spacing={1}>
                                <Grid item xs={6} >
                                    <Typography fontWeight={400} fontSize="16px" color={colors.grey}>You will be {(propertyLimit < parseInt(newPropertyLimit) ? "charged" : "credited") + ": "}</Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                    <Typography>{USDollarFormatter.format(invoicePreviewTotal)}</Typography>
                                    <Tooltip title="This amount is prorated according to the remaining time in the billing cycle.">
                                        <Help sx={{height: 16, color: colors.greyDark}} />
                                    </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography fontWeight={400} fontSize="16px" color={colors.grey}>New recurring charge:</Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography>{USDollarFormatter.format(0.14 + .99 * parseInt(newPropertyLimit))} / month</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box marginTop={theme.spacing(4)} gap={theme.spacing(1.5)} display="flex" flexDirection="row" justifyContent="center" alignItems={"center"}>
                            <Button disabled={loading} variant="outlined" color="primary" onClick={() => setLimitConfirmationModalOpen(false)}>Cancel</Button>
                            <Button disabled={loading} variant="contained" color="success" onClick={() => ConfirmUpdateLimit()}>{`Confirm ${propertyLimit < parseInt(newPropertyLimit) ? "Payment" : "Reduction"}`}</Button>
                            {
                                loading &&
                                <CircularProgress size={24} sx={{
                                    position: 'absolute',
                                    left: '57%',
                                }} />
                            }
                        </Box>
                    </Box>
                </Card>
            </Modal>
            <Modal
                open={tooManyPropertiesModalOpen}
                onClose={() => setTooManyPropertiesModalOpen(false)}
            >
                <Card sx={{ ...modalStyle, width: 420 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" >
                        <Box width="100%" display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(0.5)}>
                            <Box flex={1} />
                            <IconButton onClick={() => setTooManyPropertiesModalOpen(false)} sx={{ backgroundColor: "white" }}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Typography fontSize="28px" fontWeight={500} lineHeight="30px" textAlign="center">Remove Existing Properties</Typography>
                        <Box margin={theme.spacing(1)} display="flex" flexDirection="column" alignItems="center">
                            <Typography>You currently have more properties than your new plan would allow. Please remove existing properties to reduce your limit.</Typography>
                        </Box>
                    </Box>
                </Card>
            </Modal>
            <AppBar position='sticky' elevation={0} sx={{ bgcolor: colors.SchemesSurfaceContainerLow, width: `calc(100vw - ${navDrawerWidth}px)` }}>
                <Toolbar sx={{ margin: `${theme.spacing(2)} ${theme.spacing(4)} 0`, bgcolor: "white", borderRadius: theme.spacing(4), height: "84px" }}>
                    <Box display={"flex"} flexDirection={"row"} width={"100%"} >
                        <Typography variant="h4">My Account</Typography>
                        <Box flex={1} />
                        {editing ?
                            <Button startIcon={<Save />} variant='contained' onClick={() => SaveChanges()}>Save</Button>
                            :
                            <Button startIcon={<Edit />} variant="contained" color="secondary" onClick={() => { setEditing(true) }}>Edit</Button>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ width: "100%", padding: `${theme.spacing(4)}` }} display="flex" flexDirection="column" gap={theme.spacing(4)} flex={1}>
                <Card sx={{
                    padding: theme.spacing(3),
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Personal Info</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField disabled={!editing} fullWidth label="Display Name" name="displayName" value={fields.displayName} onChange={handleAccountChange} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField disabled={!editing} fullWidth label="Given Name" name="givenName" value={fields.givenName} onChange={handleAccountChange} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField disabled={!editing} fullWidth label="Surname" name="surname" value={fields.surname} onChange={handleAccountChange} />
                        </Grid>
                        {
                            fields.email != "" &&
                            <Grid item xs={3}>
                                <Tooltip title="For security purposes, you cannot change your email.">
                                    <TextField disabled fullWidth label="Email" name="email" value={fields.email} onChange={handleAccountChange} />
                                </Tooltip>
                            </Grid>
                        }
                        {/* <Grid item xs={4}>
                            <TextField disabled={!editing} fullWidth label="City" name="city" value={fields.city} onChange={handleAccountChange} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField disabled={!editing} fullWidth label="State" name="state" value={fields.state} onChange={handleAccountChange} />
                        </Grid> */}
                    </Grid>
                </Card>
                <Card sx={{
                    padding: theme.spacing(3),
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Subscription</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography fontWeight={400} fontSize="16px" color={colors.grey}>Current Subscription: </Typography>
                            <Typography fontWeight={400}>{`${USDollarFormatter.format(subscriptionMonthlyCost)} / month`}</Typography>
                        </Grid>
                        {
                            subscriptionScheme === "perProperty" &&
                            <>
                                <Grid item xs={2}>
                                    <Typography fontWeight={400} fontSize="16px" color={colors.grey}>Property Limit:</Typography>
                                    <Typography fontWeight={400}>{propertyLimit}</Typography>

                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" onClick={() => setManageLimitModalOpen(true)} >Manage Limit</Button>
                                </Grid>
                            </>
                        }
                        <Grid item xs={4}>
                            <Button variant="contained" target="_blank" href={STRIPE_MANAGEMENT_URL} >Manage Payment</Button>
                        </Grid>
                    </Grid>
                </Card>

                <Box display="flex" flexDirection="row" paddingBottom={theme.spacing(4)}>
                    <Box flex={1} />
                    <Button startIcon={<Logout />} variant="contained" color="secondary" sx={{ bgcolor: colors.violetLighter, color: colors.violetDark, width: 192 }} onClick={() => instance.logoutRedirect()} >Logout</Button>
                    <Box flex={1} />
                </Box>
            </Box>
        </Box>
    );
}

