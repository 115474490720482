import { createContext, useContext, useState, ReactNode } from "react";
import englishPack from "./lib/languages/english";
import chinesePack from "./lib/languages/chinese";
import { LangPack } from "./lib/languages/langpack";


// Define the shape of your context value
interface LanguageContextProps {
    currentLanguage: string;
    langpack: LangPack;
    setLanguage: (language: string) => void;
}

// Create the context with an empty default value (will be provided later)
const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

// Custom hook to use the LanguageContext easily
export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a languageProvider');
    }
    return context;
};

// Provider component
export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [langpack, setLangPack] = useState<LangPack>(englishPack); // Default to 'EN'
    const [currentLanguage, setCurrentLanguage] = useState<string>('EN')

    const setLanguage = (language: string) => {
        setCurrentLanguage(language)
        switch (language) {
            case 'EN':
                setLangPack(englishPack);
                break;
            case 'ZH':
                setLangPack(chinesePack);
                break;
            default:
                console.warn(`Unsupported language: ${language}`);
                break;
        }
    };

    return (
        <LanguageContext.Provider value={{ currentLanguage, langpack, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};