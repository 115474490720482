export interface BigEvent {
    title: string,
    start: Date,
    end: Date,
    allDay: boolean
    resource: ExtraData,
}

export interface ExtraData {
    summary: string,
    id: number,
    calendarEventPropertyInfo: CalendarEventPropertyInfo
}

export interface CalendarEventPropertyInfo {
    propertyAddress: string,
    propertyId: number
}

export const CUSTOM_EVENT_PATH: string = "CustomEvent/";

export interface CustomViewProps {
    events: BigEvent[],
    date: Date
}

export const nonePropertyInfo: CalendarEventPropertyInfo = {
    propertyAddress: "None",
    propertyId: 0,
};