import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { USER_IMPERSONATION_SCOPE } from "./scopes";
import { BlobUploadCommonResponse, ContainerClient } from "@azure/storage-blob";
import { GetAvailableBlobName } from "./utils";

export async function uploadFileToUserContainer(file: File, instance: IPublicClientApplication, user: AccountInfo, preferredBlobName?: string): Promise<{blobName: string, uploadResponse: BlobUploadCommonResponse}>{
    const intendedBlobName = preferredBlobName ?? file.name;
    const accessToken = await instance.acquireTokenSilent({
        scopes: [USER_IMPERSONATION_SCOPE],
        account: user
    });
    const response = await fetch("https://propayafunctionsdev.azurewebsites.net/api/AcquireStorageSAS", {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken.accessToken
        },
        credentials: "include",
        mode: "cors",
    });
    const SAS = await response.json();
    const containerClient = new ContainerClient(SAS);
    const blockBlobClient = await GetAvailableBlobName(intendedBlobName, containerClient);
    const uploadResponse =  await blockBlobClient.uploadData(file);
    return {
        blobName: blockBlobClient.name,
        uploadResponse: uploadResponse
    };
}