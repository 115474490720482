import { AppBar, Box, Button, ButtonBase, Card, Grid, IconButton, InputAdornment, MenuItem, Modal, TextField, Toolbar, Typography, useTheme } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SidebarWidthContext } from "./Page";
import { useMsal } from "@azure/msal-react";
import { ArrowBack, Close, Delete, Download, Edit, InsertDriveFileOutlined } from "@mui/icons-material";
import { DateTime } from "luxon";
import { ORIGIN } from "../App";
import { INVALID_URL, UploadCardContent } from "./PropertyDetail";
import { useDropzone } from "react-dropzone";
import colors from "../lib/colors";
import { BlobClient, ContainerClient } from "@azure/storage-blob";
import normalizeUrl from "normalize-url";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import error from "../images/error.svg";
import mime from 'mime';
import { EXPENSE_API_PATH } from "../lib/apiPaths";
import { READ_SCOPE, USER_IMPERSONATION_SCOPE, WRITE_SCOPE } from "../lib/scopes";
import { ExpenseDTO } from "../lib/dataTransferObjects";
import { expenseSchema, ExpenseSchemaResult, reductoExtract, ReductoResponse } from "../lib/reducto";
import { DownloadFile, GetAvailableBlobName, PreviewFile, VisuallyHiddenInput } from "../lib/utils";
import { ExternalLinkButton } from "./ExternalLinkButton";
import { states } from "../lib/states";
import { UploadState } from "../lib/enums";
import { uploadFileToUserContainer } from "../lib/blobStorage";

var parser = require('parse-address');

interface ExpenseDetailProps {
    creationForm: boolean
}

export function ExpenseDetail({ creationForm }: ExpenseDetailProps) {
    const navigate = useNavigate();
    const navDrawerWidth = useContext(SidebarWidthContext);
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const { idInput, expenseId } = useParams();
    const propertyURL = "/Properties/" + idInput + "#expenses";
    const id = expenseId == undefined ? undefined : parseInt(expenseId);
    const [editing, setEditing] = useState(creationForm);
    const theme = useTheme();
    const [expense, setExpense] = useState<ExpenseDTO | null>(null);
    const [fields, setFields] = useState<ExpenseDTO>({
        id: 0,
        cost: 0,
        title: "",
        description: "",
        note: "",
        date: "",
        paidByFirstName: "",
        paidByLastName: "",
        paidByEmail: "",
        paidByPhone: "",
        paymentMethod: "",
        blobName: "",
        vendor: {
            firstName: "",
            lastName: "",
            name: "",
            email: "",
            website: "",
            phone: "",
            streetNumber: "",
            streetName: "",
            city: "",
            zipCode: "",
            state: "",
        }
    });
    const [date, setDate] = useState<DateTime | null>(DateTime.now());
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [uploadState, setUploadState] = useState(UploadState.NotUploaded);
    const [file, setFile] = useState(new File([], "No File Selected"));
    const [uploadErrorText, setUploadErrorText] = useState("");
    const [websiteError, setWebsiteError] = useState(false);
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "747px",
        bgcolor: 'white',
        border: '1px #DED8E1 solid',
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(4),
    }

    useEffect(() => {
        if (!creationForm && expense === null) FetchExpense();
    }, [expense]);

    async function FetchExpense() {
        if (id == undefined) return;
        const expenseAccessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const expenseResponse = await fetch(ORIGIN + EXPENSE_API_PATH + id.toString(), {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + expenseAccessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const body: ExpenseDTO = await expenseResponse.json();
        setFields(body);
        setExpense(body);
        setDate(DateTime.fromISO(body.date));
        if (body.blobName == "") return;
        const blobAccessToken = await instance.acquireTokenSilent({
            scopes: [USER_IMPERSONATION_SCOPE],
            account: user
        });
        const SASresponse = await fetch("https://propayafunctionsdev.azurewebsites.net/api/AcquireReadSAS?" + "blobName=" + encodeURIComponent(body.blobName), {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + blobAccessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        const SAS = await SASresponse.json();
        const blobClient = new BlobClient(SAS);
        const blob = await blobClient.download().then(response => response.blobBody);
        if (blob === undefined) return;
        const blobName = blobClient.name.substring(body.blobName.indexOf("expenses/") + 9);
        const mimeFileType = mime.getType(blobName) ?? "";
        setFile(new File([blob], blobName, { type: mimeFileType }));
        setUploadState(UploadState.Done);
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        let data: ExpenseDTO = {
            ...fields
        };
        if (date == null) data.date = "";
        else data.date = date.toISO() ?? "";
        if (data.vendor.website != null && data.vendor.website != "") {
            try {
                data.vendor.website = normalizeUrl(data.vendor.website, { defaultProtocol: "https" });
            }
            catch {
                setWebsiteError(true);
                return;
            }
        }
        setEditing(false);
        setWebsiteError(false);
        setFields({
            ...data
        });

        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });
        const method = creationForm ? "POST" : "PUT";
        const suffix = creationForm ? "?propertyId=" + idInput : id?.toString();
        const response = await fetch(ORIGIN + EXPENSE_API_PATH + suffix, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        if (creationForm) navigate(propertyURL);
    }

    async function handleFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files == null) return setUploadErrorText("Invalid file, please upload correct file type.");
        if (event.target.files[0].size > 10_000_000) return setUploadErrorText("File too large.");
        FileChosen(event.target.files[0])
    }

    async function FileChosen(file: File) {
        setFile(file);
        setUploadState(UploadState.UploadingToBlob);
        const {blobName, uploadResponse} = await uploadFileToUserContainer(file, instance, user, idInput + "/expenses/" + file.name);
        if (uploadResponse._response.status != 201) return;
        setFile(new File([file], blobName.substring((idInput + "/expenses/").length), { type: file.type }));
        setUploadState(UploadState.ReductoProcessing);
        try {
            const reductoResult = await reductoExtract<ExpenseSchemaResult>(blobName, expenseSchema, instance, user);
            const parsed = parser.parseLocation(reductoResult.vendorAddress);
            console.log(parsed);
            let streetName: string = parsed.street + " " + parsed.type;
            if (reductoResult.vendorAddress === undefined || reductoResult.vendorAddress == "") {
                streetName = "";
            }
            else if (parsed.prefix !== "" && parsed.prefix !== undefined) streetName = parsed.prefix + " " + streetName;
            setFields({
                id: 0,
                cost: reductoResult.totalCost,
                title: reductoResult.summary,
                description: reductoResult.detailedDescription,
                note: fields.note,
                date: reductoResult.dateOfPurchase,
                paidByFirstName: reductoResult.payerFirstName,
                paidByLastName: reductoResult.payerLastName,
                paidByEmail: reductoResult.payerEmail,
                paidByPhone: reductoResult.payerPhone,
                paymentMethod: reductoResult.paymentMethod,
                blobName: blobName,
                vendor: {
                    firstName: reductoResult.contractorFirstName,
                    lastName: reductoResult.contractorLastName,
                    name: reductoResult.vendorName,
                    email: reductoResult.vendorEmail,
                    website: reductoResult.vendorWebsite,
                    phone: reductoResult.vendorPhone,
                    streetNumber: parsed.number,
                    streetName: streetName,
                    city: parsed.city,
                    zipCode: parsed.zip,
                    state: parsed.state,
                }
            });
            setDate(DateTime.fromISO(reductoResult.dateOfPurchase));
        }
        catch {
            alert("Failed to parse expense");
        }
        finally {
            setUploadState(UploadState.Done);
        }
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length == 0) return setUploadErrorText("Invalid file, please upload correct file type.");
        FileChosen(acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'application/pdf': [".pdf"],
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            "image/heic": []
        },
        disabled: (uploadState != UploadState.NotUploaded)
    });

    function handleVendorChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            vendor: {
                ...prevData.vendor,
                [e.target.name]: e.target.value
            }
        }));
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }

    async function handleDelete() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [WRITE_SCOPE],
            account: user
        });
        await fetch(ORIGIN + EXPENSE_API_PATH + expense?.id, {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            mode: "cors",
            credentials: "include",
        });
        navigate(propertyURL);
    }

    return (
        <Box display="flex" flexDirection="column" height="100vh" component="form" onSubmit={handleSubmit}>
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
            >
                <Box sx={modalStyle}>
                    <Box margin={theme.spacing(3)} display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" width="100%">
                            <Box flex={1} />
                            <IconButton onClick={() => setDeleteModalOpen(false)}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box height="273px" display="flex" flexDirection="column" textAlign="center" justifyContent="center" alignItems="center">
                            <Box component="img" src={error} width="120px" height="120px" />
                            <Typography variant="h5" marginTop={theme.spacing(4)} >{"Are you sure you want to delete this expense?"}</Typography>
                            <Box marginTop={theme.spacing(4)} gap={theme.spacing(1.5)} display="flex" flexDirection="row" justifyContent="center">
                                <Button variant="outlined" color="primary" onClick={() => setDeleteModalOpen(false)}>No, keep</Button>
                                <Button variant="contained" color="error" onClick={() => handleDelete()}>Yes, Delete</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <AppBar position='sticky' elevation={0} color="transparent" sx={{ width: `calc(100vw - ${navDrawerWidth}px)` }}>
                <Toolbar sx={{ margin: `${theme.spacing(2)} ${theme.spacing(4)}`, bgcolor: "white", borderRadius: theme.spacing(4), height: "84px" }}>
                    <Box flexGrow={1} display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="row" gap={theme.spacing(0.5)}>
                            <IconButton sx={{ background: "white" }} onClick={() => navigate(propertyURL)}>
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="h4">{creationForm ? "Add Expense" : (expense !== null ? expense.title : "")}</Typography>
                        </Box>
                    </Box>
                    {editing ?
                        <Button sx={{ height: "48px", paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }} variant='contained' type="submit">Save</Button>
                        :
                        <Box gap={theme.spacing(1)} display="flex" flexDirection="row" >
                            <IconButton onClick={() => setDeleteModalOpen(true)}>
                                <Delete color="primary" />
                            </IconButton>
                            <Button startIcon={<Edit />} variant="contained" color="secondary" onClick={() => { setEditing(true) }}>Edit</Button>
                        </Box>
                    }
                </Toolbar>
            </AppBar>
            <Box height={`calc(100vh - 84px - ${theme.spacing(2)})`} overflow="auto" >
                {(creationForm || file.size > 0) && <Box display="flex" flexDirection="column" sx={{
                    margin: theme.spacing(4),
                    gap: theme.spacing(2)
                }}>
                    <Typography variant="h5">Receipt/Paid Invoice</Typography>
                    <Card sx={{
                        padding: theme.spacing(3),
                    }}>
                        {uploadState != UploadState.Done ?
                            <>
                                <Box
                                    {...getRootProps()}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    gap={theme.spacing(2)}
                                    sx={{
                                        background: colors.SchemesSurfaceContainerLow,
                                        padding: theme.spacing(4)
                                    }}
                                >
                                    {UploadCardContent(file, uploadState)}
                                    <VisuallyHiddenInput name="fileHolder" id="fileHolder" type="file" onChange={handleFileChanged} {...getInputProps()} />
                                    {uploadState == UploadState.NotUploaded &&
                                        <>
                                            <Button component="label" variant="contained" color="secondary">
                                                Browse
                                            </Button>
                                            <Typography textAlign="center" variant="body2" color="grey">Accepted file types: .pdf, .png, .jpg, .jpeg. <br /> Maximum file size 10 MB </Typography>
                                            <Typography fontWeight={500} color={colors.red} >{uploadErrorText}</Typography>
                                        </>
                                    }
                                </Box>
                            </>
                            :
                            <>
                                <Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(2)}>
                                    <InsertDriveFileOutlined color="primary" sx={{ width: 32, height: 32 }} />
                                    <Typography onClick={() => PreviewFile(file)} component={ButtonBase} color={colors.SchemesPrimary} sx={{ textDecoration: "underline" }} >{file.name}</Typography>
                                    <Box flex="1" />
                                    <Button color="secondary" variant='contained' startIcon={<Download />} sx={{ height: "48px" }} onClick={() => DownloadFile(file)}>Download</Button>
                                </Box>
                            </>
                        }
                        { }
                    </Card>
                </Box>}
                <Box display="flex" flexDirection="column" sx={{
                    margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
                    gap: theme.spacing(2)
                }}>
                    <Typography variant="h5">General</Typography>
                    <Card sx={{
                        padding: theme.spacing(3),
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    {
                                        editing &&
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={!editing}
                                                    fullWidth
                                                    label="Title"
                                                    name="title"
                                                    multiline
                                                    value={fields.title}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={8}>
                                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                                            <DatePicker disabled={!editing} label="Date of Expense" value={date} onChange={(value) => setDate(value)} slotProps={{ textField: { fullWidth: true } }} />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            disabled={!editing}
                                            fullWidth
                                            label="Cost"
                                            name="cost"
                                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                            value={fields.cost}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled={!editing}
                                            fullWidth
                                            label="Detailed Description"
                                            name="summary"
                                            multiline
                                            value={fields.description}
                                            onChange={handleChange}
                                            minRows={4}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={!editing}
                                    fullWidth
                                    label="Notes"
                                    name="note"
                                    multiline
                                    minRows={1}
                                    value={fields.note}
                                    onChange={handleChange}
                                    sx={{
                                        height: '100%',
                                        '& .MuiInputBase-root': {
                                            height: '100%',
                                            alignItems: 'flex-start',
                                        },
                                        '& textarea': {
                                            height: '100%',
                                            boxSizing: 'border-box',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
                <Box display="flex" flexDirection="column" sx={{
                    margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
                    gap: theme.spacing(2)
                }}>
                    <Typography variant="h5">Vendor</Typography>
                    <Card sx={{
                        padding: theme.spacing(3),
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Company Information</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="First Name" name="firstName" value={fields.vendor.firstName} onChange={handleVendorChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="Last Name" name="lastName" value={fields.vendor.lastName} onChange={handleVendorChange} />
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="Name" name="name" value={fields.vendor.name} onChange={handleVendorChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="Email" name="email" value={fields.vendor.email} onChange={handleVendorChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="Phone" name="phone" value={fields.vendor.phone} onChange={handleVendorChange} />
                            </Grid>
                            <Grid item xs={3}>
                                {editing ?
                                    <TextField
                                        error={websiteError}
                                        helperText={websiteError ? INVALID_URL : ""}
                                        fullWidth
                                        label="Website"
                                        name="website"
                                        value={fields.vendor.website}
                                        onChange={handleVendorChange}
                                    />
                                    :
                                    <ExternalLinkButton href={fields.vendor.website} >
                                        {
                                            fields.vendor.website != null && fields.vendor.website != "" ? (new URL(fields.vendor.website)).hostname : "No Website"
                                        }
                                    </ExternalLinkButton>
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">Company Address</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField disabled={!editing} fullWidth label="Street Number" name="streetNumber" value={fields.vendor.streetNumber} onChange={handleVendorChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="Street Name" name="streetName" value={fields.vendor.streetName} onChange={handleVendorChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="City" name="city" value={fields.vendor.city} onChange={handleVendorChange} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    select
                                    disabled={!editing}
                                    fullWidth
                                    value={fields.vendor.state}
                                    label="State"
                                    SelectProps={{
                                        renderValue: (value: any) => value
                                    }}
                                    name="state"
                                    onChange={handleVendorChange}
                                >
                                    {
                                        Object.keys(states).map((abbreviation) => <MenuItem key={abbreviation} value={abbreviation}>{states[abbreviation]}</MenuItem>)
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField disabled={!editing} fullWidth label="ZIP Code" name="zipCode" value={fields.vendor.zipCode} onChange={handleVendorChange} />
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
                <Box display="flex" flexDirection="column" sx={{
                    margin: `${theme.spacing(2)} ${theme.spacing(4)}`,
                    gap: theme.spacing(2)
                }}>
                    <Typography variant="h5">Payment</Typography>
                    <Card sx={{
                        padding: theme.spacing(3),
                        marginBottom: theme.spacing(2)
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Paid By</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField disabled={!editing} fullWidth label="First Name" name="paidByFirstName" value={fields.paidByFirstName} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField disabled={!editing} fullWidth label="Last Name" name="paidByLastName" value={fields.paidByLastName} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField disabled={!editing} fullWidth label="Phone" name="paidByPhone" value={fields.paidByPhone} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="Email" name="paidByEmail" value={fields.paidByEmail} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField disabled={!editing} fullWidth label="Payment Method" name="paymentMethod" value={fields.paymentMethod} onChange={handleChange} />
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
}