import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Page } from './components/Page';
import { PropertyList } from './components/PropertyList';
import { CalendarPane } from './components/calendar/CalendarPane';
import { CssBaseline, ThemeProvider, backdropClasses, createTheme } from '@mui/material';
import { Dashboard } from './components/Dashboard'
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from './authConfig';
import { Loading } from './components/Loading';
import colors from './lib/colors';
import { PropertyDetail } from './components/PropertyDetail';
import { MobileValidator } from './MobileValidator';
import { TestHome } from './components/TestHome';
import { StripeCheckout } from './components/StripeCheckout';
import { StripeReturn } from './components/StripeReturn';
import { SubscriptionValidator } from './SubscriptionValidator';
import { loadStripe } from '@stripe/stripe-js';
import { Account } from './components/Account';
import { ExpenseDetail } from './components/ExpenseDetail';
import { useEffect, useState } from 'react';
import { isMobile } from './mobileCheck';
import { MobilePropertyList } from './components/mobile/MobilePropertyList';
import { MobilePropertyDetail } from './components/mobile/MobilePropertyDetail';
import { MobileExpenseDetail } from './components/mobile/MobileExpenseDetail';
import { MobileDashboard } from './components/mobile/MobileDashboard';
import { MobileAccount } from './components/mobile/MobileAccount';
import { MobileCalendarPane } from './components/mobile/calendar/MobileCalendarPane';
import { LanguageProvider } from './LanguageContext';

let originURL: string | undefined = process.env.REACT_APP_API_ORIGIN_URL;
let stripeManagementURL = "https://billing.stripe.com/p/login/test_28o00JaD94PGbg47ss";
if (originURL === "https://propayabackend.azurewebsites.net") stripeManagementURL = "https://billing.stripe.com/p/login/9AQ29uddj67868ocMM";
export const STRIPE_MANAGEMENT_URL = stripeManagementURL;
if (originURL === undefined) {
  originURL = "https://localhost:7086"
  // originURL = 'https://propayabackenddev.azurewebsites.net/'
}

const isDev = window.location.host !== "app.propaya.com";
export const IS_DEV = isDev;

let stripePublishableKey: string | undefined = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
if (stripePublishableKey === undefined) {
  stripePublishableKey = "pk_test_51Pav432MIokyMhvuz1YbZ9LElhRbSCghYrXqwEfY1egPQlAL9DfROJ3KBEmvuBUc53x8IvjXFN3BkjRnX0Bce91d00QM6brQOF";
  // stripePublishableKey = "pk_live_51Pav432MIokyMhvukZ0xWm6gV781zBIkJh5zRc24Wrf7pbKRlQM1hlI8PX1wEs2qMk0WXU9rG183JKmeNWiQMPCp00PPi67IAw";
}

export const ORIGIN: string = originURL;
export const STRIPE_PROMISE = loadStripe(stripePublishableKey);

function App() {
  const [mobile, setMobile] = useState(false);
  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none"
      },
      fontFamily: "Roboto, Arial",
      allVariants: {
        color: colors.greyDarker
      }
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            "&:hover": {
              backgroundColor: colors.violetLighter,
            },
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            // "&:hover": {
            //   backgroundColor: colors.violetLighter,
            // },
          }
        },
        defaultProps: {
          disableElevation: true
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            background: colors.violetLightest,
            "&:hover": {
              backgroundColor: colors.violetLighter,
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderColor: colors.SchemesSurfaceDim,
            borderRadius: "32px"
          }
        },
        defaultProps: {
          elevation: 0,
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: colors.greyLightest,
            borderRadius: "16px",

          }
        },
        defaultProps: {
          variant: "filled"
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            background: colors.greyLightest,
            borderRadius: "16px",
            border: `2px solid white`,
            '&.Mui-disabled': {
              background: colors.greyLightest,
            },
            "&.Mui-focused": {
              border: `2px solid ${colors.violetDark}`,
              background: colors.greyLightest
            },
            '&.Mui-disabled:hover': {
              background: colors.greyLightest,
            },
            ":hover": {
              background: colors.violetLightest
            }
          },
        },
        defaultProps: {
          disableUnderline: true
        }
      },
    },
    palette: {
      primary: {
        main: colors.violetDark,
      },
      secondary: {
        main: colors.violetLightest,
      },
      error: {
        main: colors.red,
        contrastText: "white"
      }
    }
  });

  async function delay() {
    await new Promise(r => setTimeout(r, 1000));
    setMobile(isMobile());
  }

  useEffect(() => {
    setMobile(isMobile());
    // delay();
  }, [mobile]);

  return (
    <LanguageProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={loginRequest}
              loadingComponent={Loading}
            >
              <SubscriptionValidator>
                <MobileValidator>
                  <Page />
                </MobileValidator>
              </SubscriptionValidator>
            </MsalAuthenticationTemplate>
          }>
            <Route index element={
              mobile ? <MobileDashboard /> : <Dashboard />
            } />
            <Route path="Properties/" element={
              mobile ? <MobilePropertyList /> : <PropertyList />
            } />
            <Route path="Properties/:idInput" element={
              mobile ? <MobilePropertyDetail creationForm={false} /> : <PropertyDetail creationForm={false} />
            } />
            <Route path="Properties/Add" element={
              mobile ? <MobilePropertyDetail creationForm={true} /> : <PropertyDetail creationForm={true} />
            } />
            <Route path="Properties/:idInput/Expenses/:expenseId" element={
              mobile ? <MobileExpenseDetail creationForm={false} /> : <ExpenseDetail creationForm={false} />
            } />
            <Route path="Properties/:idInput/Expenses/Add" element={
              mobile ? <MobileExpenseDetail creationForm={true} /> : <ExpenseDetail creationForm={true} />
            } />
            <Route path="Calendar/" element={
              mobile ? <MobileCalendarPane /> : <CalendarPane />
            } />
            <Route path="Account/" element={
              mobile ? <MobileAccount /> : <Account />
            } />
            {
              IS_DEV &&
              <Route path="Test/" element={
                <TestHome />
              } />
            }
          </Route>
          <Route path="checkout/" element={
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={loginRequest}
              loadingComponent={Loading}
            >
              <StripeCheckout />
            </MsalAuthenticationTemplate>
          } />
          <Route path="return/" element={
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={loginRequest}
              loadingComponent={Loading}
            >
              <StripeReturn />
            </MsalAuthenticationTemplate>
          } />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
