import { Box, Chip, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import colors from "../../../lib/colors";
import { BigEvent } from "../../calendar/calendarTypes";

export interface CalendarEventRowProps {
    event: BigEvent
}

export function MobileCalendarEventRow({ event }: CalendarEventRowProps) {
    const theme = useTheme();
    const navigate = useNavigate();
    let day: number | string = event.start.getDate();
    day = day < 10 ? "0" + day.toString() : day.toString();
    return (
        <Box padding={`${theme.spacing(2)}`} borderBottom={`1px solid ${colors.greyLighter}`} >
            <Box display="flex" flexDirection="row" alignItems="center">
                <Box height="70px" width="70px" textAlign="center" sx={{ bgcolor: colors.violetLightest }} borderRadius={theme.spacing(1.5)} padding={`${theme.spacing(1)} ${theme.spacing(1.5)}`} >
                    <Typography fontSize="32px" fontWeight={500} color={colors.greyViolet} lineHeight="normal" >{day}</Typography>
                    <Typography fontSize="16px" fontWeight={500} color={colors.greyViolet} lineHeight="normal" >{event.start.toLocaleString('default', { month: "short" })}</Typography>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" gap={1} margin={theme.spacing(1)}>
                    <Box flex={1} marginLeft={theme.spacing(2)} marginRight={theme.spacing(2)} display="flex" flexDirection="column" justifyContent="center">
                        <Typography fontSize="18px" fontWeight={600} color={colors.greyDarker} lineHeight="normal" >{event.title}</Typography>
                        <Typography fontSize="16px" fontWeight={400} color={colors.greyDark} lineHeight="normal" >{event.resource.summary}</Typography>
                    </Box>
                    {!(event.resource.calendarEventPropertyInfo.propertyAddress == null || event.resource.calendarEventPropertyInfo.propertyAddress == "" || event.resource.calendarEventPropertyInfo.propertyAddress == "None") &&
                        <Chip label={event.resource.calendarEventPropertyInfo.propertyAddress}
                            onClick={() => navigate("/Properties/" + event.resource.calendarEventPropertyInfo.propertyId)}
                            sx={{ bgcolor: colors.yellowLight, color: colors.yellowDarkest, fontSize: "16px", fontWeight: 500 }} />}
                </Box>
            </Box>
        </Box>
    );
}