import { Box, Button, ButtonBase, Card, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography, useTheme } from "@mui/material";
import { modalStyle } from "../../lib/styles";
import { Close, Download, InsertDriveFileOutlined, Upload } from "@mui/icons-material";
import { BigEvent, CalendarEventPropertyInfo } from "./calendarTypes";
import { CalendarEventDTO, NoticeEventDTO } from "../../lib/dataTransferObjects";
import colors from "../../lib/colors";
import { useCallback, useState } from "react";
import { DateTime } from "luxon";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useDropzone } from "react-dropzone";
import { UploadState } from "../../lib/enums";
import { uploadFileToUserContainer } from "../../lib/blobStorage";
import { noticeSchema, NoticeSchemaResult, reductoExtract } from "../../lib/reducto";
import { useMsal } from "@azure/msal-react";
import { areAddressesEqual, DownloadFile, PreviewFile, VisuallyHiddenInput } from "../../lib/utils";
import { UploadCardContent } from "../PropertyDetail";
import { useLanguage } from "../../LanguageContext";

interface NoticeForm {
    handleCloseModal: () => void,
    submitCreate: (e: NoticeEventDTO) => void,
    propertyInfos: CalendarEventPropertyInfo[]
}

interface NoticeFormFields {
    date: DateTime,
    title: string,
    summary: string,
    propertyInfo: CalendarEventPropertyInfo
}

export function NoticeForm({ handleCloseModal, submitCreate, propertyInfos }: NoticeForm) {
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const theme = useTheme();
    const [fields, setFields] = useState<NoticeFormFields>({
        title: "",
        date: DateTime.now(),
        summary: "",
        propertyInfo: propertyInfos[0],
    });
    const [uploadState, setUploadState] = useState(UploadState.NotUploaded);
    const [file, setFile] = useState(new File([], "No File Selected"));
    const [uploadErrorText, setUploadErrorText] = useState("");
    const {langpack, setLanguage} = useLanguage();

    function handleDateChange(newDate: DateTime | null) {
        if (newDate === null) return;
        setFields(prevData => ({
            ...prevData,
            date: newDate
        }))
    }

    function handlePropertySelectionChange(event: SelectChangeEvent) {
        if (event.target.value === null) return;
        console.log(event.target.value);
        // @ts-ignore
        setFields(prevData => ({
            ...prevData,
            propertyInfo: event.target.value
        }))
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }

    async function handleSubmitCreate(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const start: DateTime = fields.date.set({
            hour: 0,
        });
        const end = fields.date.set({
            hour: 0,
        });
        let newEvent: NoticeEventDTO = {
            title: fields.title,
            summary: fields.summary,
            start: start.toISO() as string,
            end: end.toISO() as string,
            allDay: true,
            id: -1,
            propertyAddress: fields.propertyInfo.propertyAddress,
            associatedPropertyId: fields.propertyInfo.propertyId,
            noticeBlobName: file.name,
            noticePropertyId: fields.propertyInfo.propertyId
        };
        submitCreate(newEvent);
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length == 0) return;
        FileChosen(acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'application/pdf': [".pdf"],
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            "image/heic": []
        },
        disabled: (uploadState != UploadState.NotUploaded)
    });

    async function handleFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files == null) return setUploadErrorText("Invalid file, please upload correct file type.");
        if (event.target.files[0].size > 10_000_000) return setUploadErrorText("File too large.");
        FileChosen(event.target.files[0])
    }

    async function FileChosen(file: File) {
        setFile(file);
        setUploadState(UploadState.UploadingToBlob);
        const { blobName, uploadResponse } = await uploadFileToUserContainer(file, instance, user);
        if (uploadResponse._response.status != 201) return;
        setFile(new File([file], blobName, { type: file.type }));
        setUploadState(UploadState.ReductoProcessing);
        try {
            const reductoResult = await reductoExtract<NoticeSchemaResult>(blobName, noticeSchema, instance, user);
            let associatedPropertyInfo = propertyInfos.find(i => areAddressesEqual(i.propertyAddress, reductoResult.address ?? ""));
            if (associatedPropertyInfo === undefined) associatedPropertyInfo = propertyInfos[0];
            setFields({
                title: reductoResult.title,
                date: DateTime.fromISO(reductoResult.action_date),
                summary: reductoResult.summary,
                propertyInfo: associatedPropertyInfo,
            });
        }
        catch {
            alert("Failed to parse notice.");
        }
        finally {
            setUploadState(UploadState.Done);
        }
    }

    return (
        <Card component="form" onSubmit={handleSubmitCreate} sx={modalStyle}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography fontSize="28px" fontWeight={500} lineHeight="30px" color={colors.greyVioletDark} >{langpack.addnotice}</Typography>
                <Box flex={1} />
                <IconButton onClick={handleCloseModal}>
                    <Close />
                </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" margin={`${theme.spacing(4)} 0 0 0`} gap={theme.spacing(2)}>
                {
                    uploadState !== UploadState.Done ?
                        <>
                            <Box
                                {...getRootProps()}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                gap={theme.spacing(2)}
                                sx={{
                                    background: colors.SchemesSurfaceContainerLow,
                                    padding: theme.spacing(4),
                                    borderRadius: theme.spacing(4)
                                }}
                            >
                                {UploadCardContent(file, uploadState)}
                                <VisuallyHiddenInput name="fileHolder" id="fileHolder" type="file" onChange={handleFileChanged} {...getInputProps()} />
                                {
                                    uploadState === UploadState.NotUploaded &&
                                    <>
                                        <Button component="label" variant="contained" color="secondary">
                                            Browse
                                        </Button>
                                        <Typography textAlign="center" variant="body2" color="grey">Accepted file types: .pdf, .png, .jpg, .jpeg. <br /> Maximum file size 10 MB </Typography>
                                        <Typography fontWeight={500} color={colors.red} >{uploadErrorText}</Typography>
                                    </>
                                }
                            </Box>
                            {
                                uploadState === UploadState.NotUploaded &&
                                <Button variant="contained" color="secondary" onClick={() => setUploadState(UploadState.Done)} >Enter notice info manually</Button>
                            }
                        </>
                        :
                        <>
                            {
                                file.size !== 0 &&
                                <Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(2)}>
                                    <InsertDriveFileOutlined color="primary" sx={{ width: 32, height: 32 }} />
                                    <Typography onClick={() => PreviewFile(file)} component={ButtonBase} color={colors.SchemesPrimary} sx={{ textDecoration: "underline" }} >{file.name}</Typography>
                                    <Box flex="1" />
                                    <Button color="secondary" variant='contained' startIcon={<Download />} sx={{ height: "48px" }} onClick={() => DownloadFile(file)}>Download</Button>
                                </Box>
                            }
                            <TextField fullWidth label="Title" placeholder="Inspection" name="title" value={fields.title} onChange={handleChange} />
                            <FormControl fullWidth>
                                <InputLabel variant="filled" id="property-select-label">Choose property</InputLabel>
                                <Select
                                    // @ts-ignore
                                    labelId="property-select-label"
                                    // @ts-ignore
                                    value={fields.propertyInfo}
                                    onChange={handlePropertySelectionChange}
                                    variant="filled"
                                >
                                    {propertyInfos?.map(i => {
                                        // @ts-ignore
                                        return <MenuItem value={i}>{i.propertyAddress}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DatePicker label="Date" value={fields.date} onChange={(value) => handleDateChange(value)} slotProps={{ textField: { fullWidth: true } }} />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                            <TextField fullWidth multiline minRows={3} label="Summary" placeholder="W 34th St., New York Inspector Arrival" name="summary" value={fields.summary} onChange={handleChange} />
                        </>
                }
            {
                uploadState === UploadState.Done &&
                <Button type="submit" fullWidth variant="contained">
                    {"Create notice event"}
                </Button>
            }
            </Box>
        </Card>
    );
}