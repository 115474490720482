import { useState, useEffect, useContext } from "react";
import { ORIGIN } from "../../App";
import { Typography, Box, Button, AppBar, Toolbar, useTheme, Menu, MenuItem, Card, IconButton } from "@mui/material";
// import { PropertyRow } from "./PropertyRow";
import { Add, ArrowDropDown, ArrowDropUp, Search } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';
import colors from '../../lib/colors';
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import { SidebarWidthContext } from ".././Page";
import { PROPERTY_API_PATH } from "../../lib/apiPaths";
import { READ_SCOPE } from "../../lib/scopes";
import { ExpenseDTO, HOAPolicyDTO, LeaseDTO, OccupantDTO, OwnerDTO, PropertyDTO, TenantDTO, UtilityCompanyDTO, VendorDTO } from "../../lib/dataTransferObjects";
import { getOrdinalSuffix, USDollarFormatter } from "../../lib/utils";
import propertyIllustration from "../../images/property_illustration.svg"

interface PropertyRow {
    id: number,
    address: string,
    city: string,
    county: string,
    zip: string,
    state: string,
    dateAdded: string,
    owner: string,
    tenantName: string,
    tenantPhone: string,
    tenantEmail: string,
    leaseStart: string,
    leaseEnd: string,
    rentDueDate: number,
    rent: number,
    securityDeposit: number,
}

function PropertyToRow(p: Property) {
    let tenantName = "No Tenants";
    let tenantPhone = "None";
    let tenantEmail = "None";
    if (p.lease.tenants.length > 0) {
        tenantName = p.lease.tenants[0].firstName + " " + p.lease.tenants[0].lastName;
        tenantPhone = p.lease.tenants[0].phoneNumber;
        tenantEmail = p.lease.tenants[0].email;
    }
    let ownerName = "No Owner";
    if (p.owners.length > 0) ownerName = p.owners[0].firstName + " " + p.owners[0].lastName;
    return {
        id: p.id,
        address: p.streetNumber + " " + p.streetName,
        city: p.city,
        county: p.county,
        zip: p.zipCode,
        state: p.state,
        dateAdded: p.created.toLocaleDateString(),
        owner: ownerName,
        tenantName: tenantName,
        tenantPhone: tenantPhone,
        tenantEmail: tenantEmail,
        leaseStart: p.lease.leaseStart,
        leaseEnd: p.lease.leaseEnd,
        rentDueDate: p.lease.dueDate,
        rent: p.lease.rent,
        securityDeposit: p.lease.securityDeposit,
    }
}

const columns: GridColDef[] = [
    {
        field: 'address',
        headerName: 'Address',
        flex: 1,
        align: "center",
        headerAlign: "center"
    },
    {
        field: 'city',
        headerName: 'City',
        flex: 1
    },
    {
        field: 'county',
        headerName: 'County',
        flex: 1
    },
    {
        field: 'zip',
        headerName: 'Zip',
        flex: 1
    },
    {
        field: 'state',
        headerName: 'State',
        flex: 1
    },
    {
        field: 'dateAdded',
        headerName: 'Date Added',
        flex: 1
    },
    {
        field: 'owner',
        headerName: 'Owner',
        flex: 1
    },
    {
        field: 'tenantName',
        headerName: 'Tenant',
        flex: 1
    },
    {
        field: 'tenantPhone',
        headerName: 'Tenant Phone',
        flex: 1
    },
    {
        field: 'tenantEmail',
        headerName: 'Tenant Email',
        flex: 1
    },
    {
        field: 'leaseStart',
        headerName: 'Lease Start',
        flex: 1,
        valueFormatter(params) {
            return new Date(params.value).toLocaleDateString();
        },
    },
    {
        field: 'leaseEnd',
        headerName: 'Lease End',
        flex: 1,
        valueFormatter(params) {
            return new Date(params.value).toLocaleDateString();
        },
    },
    {
        field: 'rentDueDate',
        headerName: 'Rent Due Date',
        flex: 1,
        valueFormatter(params) {
            return params.value + getOrdinalSuffix(params.value);
        },
    },
    {
        field: 'rent',
        headerName: 'Rent',
        flex: 1,
        valueFormatter(params) {
            return USDollarFormatter.format(params.value);
        },
    },
    {
        field: 'securityDeposit',
        headerName: 'Security Deposit',
        flex: 1,
        valueFormatter(params) {
            return USDollarFormatter.format(params.value);
        },
    },
];

enum PropertyType {
    All,
    Managed,
    Owned,
}

export interface Property {
    id: number,
    created: Date,
    streetNumber: string,
    streetName: string,
    owners: OwnerDTO[],
    expenses: ExpenseDTO[],
    city: string,
    state: string,
    zipCode: string,
    county: string,
    lease: LeaseDTO,
    hoaPolicy: HOAPolicyDTO,
    electricity: UtilityCompanyDTO,
    water: UtilityCompanyDTO,
    gas: UtilityCompanyDTO,
    trash: UtilityCompanyDTO,
}

export function MobilePropertyList() {
    const navigate = useNavigate();
    const theme = useTheme();
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const [managedProperties, setManagedProperties] = useState<Property[] | null>(null);
    const [ownedProperties, setOwnedProperties] = useState<Property[] | null>(null);
    const [displayedProperties, setDisplayedProperties] = useState<Property[] | null>(null);
    const [propertyType, setPropertyType] = useState<PropertyType>(PropertyType.All);
    const [propertyTypeMenuAnchorEl, setPropertyTypeMenuAnchorEl] = useState<null | HTMLElement>(null);
    const propertyTypeMenuOpen = Boolean(propertyTypeMenuAnchorEl);
    const [displayedRows, setDisplayedRows] = useState<PropertyRow[]>([]);
    const [managedPropertyRows, setManagedPropertyRows] = useState<PropertyRow[]>([]);
    const [ownedPropertyRows, setOwnedPropertyRows] = useState<PropertyRow[]>([]);
    const navDrawerWidth = useContext(SidebarWidthContext);
    const horizontalMargin = theme.spacing(2);

    const handleClickPropertyTypeMenu = (event: React.MouseEvent<HTMLElement>) => {
        setPropertyTypeMenuAnchorEl(event.currentTarget);
    };

    const handleClosePropertyTypeMenu = (selection: PropertyType) => {
        switch (selection) {
            case PropertyType.All: {
                setDisplayedProperties((managedProperties ?? []).concat(ownedProperties ?? []))
                setDisplayedRows(managedPropertyRows.concat(ownedPropertyRows));
                break;
            }
            case PropertyType.Managed: {
                setDisplayedProperties(managedProperties);
                setDisplayedRows(managedPropertyRows);
                break;
            }
            case PropertyType.Owned: {
                setDisplayedProperties(ownedProperties);
                setDisplayedRows(ownedPropertyRows);

                break;
            }
            default: {
                break;
            }
        }
        setPropertyType(selection);
        setPropertyTypeMenuAnchorEl(null);
    };

    async function FetchProperties() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const response = await fetch(ORIGIN + PROPERTY_API_PATH, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        let body: PropertyDTO[] = await response.json();
        let newProperties: Property[] = body.map(p => {
            return {
                ...p,
                created: new Date(p.created)
            }
        });
        let newPropertyRows: PropertyRow[] = newProperties.map(PropertyToRow);
        let newManagedProperties: Property[] = [];
        let newOwnedProperties: Property[] = [];
        let newManagedPropertyRows: PropertyRow[] = [];
        let newOwnedPropertyRows: PropertyRow[] = [];
        newProperties.forEach(p => {
            if (p.owners[0].email == user.username) {
                newOwnedProperties.push(p);
                newOwnedPropertyRows.push(PropertyToRow(p));
            }
            else {
                newManagedProperties.push(p);
                newManagedPropertyRows.push(PropertyToRow(p));
            }
        });
        setManagedProperties(newManagedProperties);
        setManagedPropertyRows(newManagedPropertyRows);
        setOwnedProperties(newOwnedProperties);
        setOwnedPropertyRows(newOwnedPropertyRows);
        setDisplayedProperties(newProperties);
        setDisplayedRows(newPropertyRows);
    };

    useEffect(() => {
        if (managedProperties == null) {
            FetchProperties();
        }
    }, [managedProperties]);

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        navigate(params.id.toString())
    };

    return (
        <Box sx={{ flex: "1", overflow: "auto" }} >
            <AppBar position='sticky' elevation={0} sx={{ bgcolor: colors.SchemesSurfaceContainerLow, width: "100vw" }}>
                <Toolbar sx={{ margin: `${theme.spacing(2)} ${horizontalMargin} 0`, bgcolor: "white", borderRadius: theme.spacing(4), height: "84px" }}>
                    <Typography flexGrow={1} variant="h4">My Properties</Typography>
                    <Box gap={theme.spacing(1)} display="flex" flexDirection="row">
                        {/* <TextField
                            placeholder='Search'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{padding: theme.spacing(1)}}>
                                        <Search />
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                                sx: {
                                    borderRadius: "100px",
                                    background: colors.SchemesSurfaceContainerHigh,
                                    width: "360px",
                                    height: "48px"
                                }
                            }}
                            variant='standard'
                        /> */}
                        {/* <Button sx={{ height: "48px" }} variant="contained" color='secondary' onClick={handleClickPropertyTypeMenu} startIcon={propertyTypeMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}>
                            {PropertyTypeMenuNames[propertyType]}
                        </Button>
                        <Menu
                            anchorEl={propertyTypeMenuAnchorEl}
                            open={propertyTypeMenuOpen}
                            onClose={() => handleClosePropertyTypeMenu(propertyType)}
                        >
                            <MenuItem onClick={() => handleClosePropertyTypeMenu(PropertyType.All)} disableRipple>
                                {PropertyTypeMenuNames[PropertyType.All]}
                            </MenuItem>
                            <MenuItem onClick={() => handleClosePropertyTypeMenu(PropertyType.Managed)} disableRipple>
                                {PropertyTypeMenuNames[PropertyType.Managed]}
                            </MenuItem>
                            <MenuItem onClick={() => handleClosePropertyTypeMenu(PropertyType.Owned)} disableRipple>
                                {PropertyTypeMenuNames[PropertyType.Owned]}
                            </MenuItem>
                        </Menu> */}
                        <IconButton sx={{ bgcolor: colors.violetDark }} onClick={() => navigate("Add")}>
                            <Add color="secondary" />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box height={`calc(100vh - 84px - ${theme.spacing(2)} - 48px)`} padding={`${theme.spacing(2)} ${horizontalMargin}`}>
                <Card sx={{
                    padding: theme.spacing(3),
                    background: "white",
                    border: 0,
                    height: "100%",
                }}>
                    {
                        managedProperties === null || ownedProperties === null ?
                            <Typography margin={theme.spacing(6)} fontWeight={350} textAlign="center" variant="h3">No Properties Yet</Typography>
                            :
                            (
                                displayedRows.length === 0 ?
                                    <Box display="flex" flexDirection="column">
                                        <Box component="img" src={propertyIllustration} height="40vh" marginTop={theme.spacing(4)} />
                                        <Typography margin={theme.spacing(6)} fontWeight={350} textAlign="center" variant="h4">No Properties Yet</Typography>
                                    </Box>
                                    :
                                    <DataGrid
                                        columns={columns}
                                        rows={displayedRows}
                                        hideFooter={displayedRows.length < 25}
                                        sx={{
                                            "& .MuiDataGrid-row:hover": {
                                                cursor: "pointer"
                                            },
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                            },
                                            '.MuiDataGrid-columnHeaderTitle': {
                                                fontSize: "16px",
                                                color: colors.greyVioletDark
                                            },
                                            '& .MuiDataGrid-columnHeader': {
                                                '& .MuiDataGrid-iconButtonContainer': {
                                                    marginLeft: '8px'
                                                },
                                            },
                                            border: "0px"
                                        }}
                                        onRowClick={handleRowClick}
                                        disableRowSelectionOnClick
                                        disableColumnMenu
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    county: false,
                                                    zip: false,
                                                    state: false,
                                                    dateAdded: false,
                                                    leaseStart: false,
                                                    rentDueDate: false,
                                                    securityDeposit: false,
                                                    city: false,
                                                    owner: false,
                                                    tenantName: false,
                                                    tenantPhone: false,
                                                    tenantEmail: false,
                                                    leaseEnd: false,
                                                    rent: false
                                                }
                                            },
                                            sorting: {
                                                sortModel: [{ field: 'address', sort: 'asc' }],
                                            }
                                        }}
                                    />
                            )
                    }
                </Card>
            </Box>
        </Box>
    )
}