import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { AccountCircle, ArrowBack, ArrowLeft, ChevronLeft, ChevronRight, Menu } from "@mui/icons-material";
import { COLLAPSED_WIDTH, EXPANDED_WIDTH } from "./Page";
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import React, { useState } from 'react';
import { useLanguage } from "../LanguageContext";

interface NavDrawerFooterProps {
    navDrawerCollapsed: boolean
    setNavDrawerCollapsed: (collapsed: boolean) => void
    setNavDrawerWidth: (width: number) => void
}

export function NavDrawerFooter({ navDrawerCollapsed, setNavDrawerCollapsed, setNavDrawerWidth }: NavDrawerFooterProps) {
    const theme = useTheme();
    const { instance } = useMsal();
    let currentPath: string = useLocation().pathname.split("/")[1];

    const {currentLanguage, langpack, setLanguage} = useLanguage();

    const handleChange = (event: React.MouseEvent<HTMLElement>, newLang: string | null) => {
        if (newLang !== null) {
            setLanguage(newLang);
          }
      };

    async function OnClickLogout() {
        instance.logoutRedirect();
    }

    return (
        <Box marginTop="auto" marginBottom={0} display='inline-flex' flexDirection="column" width="100%" >
            <Box alignSelf="stretch">
                <Divider variant="middle" />
            </Box>
            <List>
                <ListItem >
                    <ListItemButton component={Link} to={"/Account/"}>
                        <ListItemIcon>
                            <AccountCircle />
                        </ListItemIcon>
                        {!navDrawerCollapsed &&
                            <ListItemText primaryTypographyProps={{ fontWeight: currentPath == "Account" ? "bold" : "regular", fontSize: 18 }} 
                            primary={langpack.account}  />
                        }
                    </ListItemButton>
                </ListItem >
                <ListItem >
                    <ListItemButton onClick={() => {
                        setNavDrawerCollapsed(!navDrawerCollapsed)
                        if( navDrawerCollapsed ){
                            setNavDrawerWidth(EXPANDED_WIDTH);
                        }
                        else{
                            setNavDrawerWidth(COLLAPSED_WIDTH);
                        }
                    }}>
                        <ListItemIcon>
                            {
                                navDrawerCollapsed ? 
                                <ChevronRight />
                                :
                                <ChevronLeft />
                            }
                        </ListItemIcon>
                        {!navDrawerCollapsed &&
                            <ListItemText primaryTypographyProps={{fontSize: 18}} 
                            primary={langpack.collapse}  />
                        }
                    </ListItemButton>
                </ListItem>
                <ListItem >
                    <Box 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center" 
                        width="100%" 
                        paddingY={2} // Optional padding for better spacing
                    >
                        <ToggleButtonGroup
                            orientation={navDrawerCollapsed ? "vertical" : "horizontal"}
                            value={currentLanguage}
                            exclusive
                            // onChange={(event, newLang) => setLanguage(newLang)} // Update global view state
                            onChange={handleChange}
                            aria-label="language"
                        >
                            <ToggleButton value="EN">EN</ToggleButton>
                            <ToggleButton value="ZH">中文</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </ListItem>
            </List>
        </Box>
    );
}