import { AppBar, Box, Card, Grid, Toolbar, Typography, useTheme } from "@mui/material";
import { ReactNode, useContext, useEffect, useState } from "react";
import { ORIGIN } from "../App";
import { useMsal } from "@azure/msal-react";
import colors from "../lib/colors";
import { AccountBalanceWalletOutlined, Calculate, Speed } from "@mui/icons-material";
import { DateTime } from "luxon";
import calendarIllustration from "../images/calendar_illustration.svg";
import { SidebarWidthContext } from "./Page";
import { READ_SCOPE } from "../lib/scopes";
import { BigEventsSortByDate, percentFormatter, USDollarFormatter } from "../lib/utils";
import { CALENDAR_API_PATH, HOA_API_PATH, LEASE_API_PATH, PROPERTY_API_PATH } from "../lib/apiPaths";
import { CalendarEventDTO } from "../lib/dataTransferObjects";
import { CalendarEventRow } from "./calendar/CalendarEventRow";
import { BigEvent } from "./calendar/calendarTypes";
import { useLanguage } from "../LanguageContext";

const today = DateTime.now();

interface MetricCardProps {
    metricName: string,
    metricValue: string,
    icon: ReactNode,
}

export function Dashboard() {
    const theme = useTheme();
    const navDrawerWidth = useContext(SidebarWidthContext);
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const [income, setIncome] = useState<number | null>(null);
    const [hoaFees, setHOAFees] = useState<number | null>(null);
    const [calEvents, setCalEvents] = useState<BigEvent[] | null>(null);
    const [occupancyRate, setOccupancyRate] = useState<number | null>(null);
    const iconSx = {
        color: "white",
        borderRadius: theme.spacing(1),
        width: 48,
        height: 48,
        padding: theme.spacing(1),
    }
    const {langpack, setLanguage} = useLanguage();

    function MetricCard({ metricValue, metricName, icon }: MetricCardProps) {
        return (
            <Card sx={{ padding: theme.spacing(4), height: "180px", display: "flex", flexDirection: "column" }}>
                <Box display="flex" flexDirection="row">
                    {icon}
                    <Box flex={1} />
                    <Box textAlign="right" >
                        <Typography fontWeight={500} fontSize="16px" >{metricName}</Typography>
                        <Typography fontWeight={400} fontSize="16px" color={colors.grey} >{today.monthLong + " " + today.year}</Typography>
                    </Box>
                </Box>
                <Box flex={1} />
                <Typography fontWeight={500} fontSize="40px" >{metricValue}</Typography>
            </Card>
        );
    }

    async function FetchRentIncome() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const response = await fetch(ORIGIN + LEASE_API_PATH + "RentTotal", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        let body: number = await response.json();
        console.log(body);
        setIncome(body);
    }

    async function FetchHOAFees() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const response = await fetch(ORIGIN + HOA_API_PATH + "FeesTotal", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        let body: number = await response.json();
        console.log(body);
        setHOAFees(body);
    }

    async function FetchCalEvents() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const response = await fetch(ORIGIN + CALENDAR_API_PATH + "TenDayForecast", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        let body: CalendarEventDTO[] = await response.json();
        let events: BigEvent[] = body.map(e => ({
            title: e.title,
            start: DateTime.fromISO(e.start).toJSDate(),
            end: DateTime.fromISO(e.start).toJSDate(),
            allDay: true,
            resource: {
                summary: e.summary,
                id: e.id,
                calendarEventPropertyInfo: {
                    propertyAddress: e.propertyAddress,
                    propertyId: e.associatedPropertyId
                }
            }
        }));
        events.sort(BigEventsSortByDate);
        console.log(events);
        setCalEvents(events);
    }

    async function FetchOccupancyRate() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        const response = await fetch(ORIGIN + PROPERTY_API_PATH + "OccupancyRate", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        if( !response.ok ) return setOccupancyRate(0);
        let body: number = await response.json();
        console.log(body);
        setOccupancyRate(body);
    }

    useEffect(() => {
        if( income == null ) FetchRentIncome();
        if( hoaFees == null ) FetchHOAFees();
        if( calEvents == null ) FetchCalEvents();
        if( occupancyRate == null ) FetchOccupancyRate();
    }, [income, hoaFees, calEvents, occupancyRate]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto", bgcolor: colors.SchemesSurfaceContainerLow, overflowX: "hidden" }}>
            <AppBar position='sticky' elevation={0} sx={{ bgcolor: colors.SchemesSurfaceContainerLow, width: `calc(100vw - ${navDrawerWidth}px)` }}>
                <Toolbar sx={{ margin: `${theme.spacing(2)} ${theme.spacing(4)} 0`, bgcolor: "white", borderRadius: theme.spacing(4), height: "84px" }}>
                    <Typography flexGrow={1} variant="h4">{langpack.dashboard}</Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: "flex", width: "100%", padding: `${theme.spacing(2)} ${theme.spacing(4)}` }} flex={1}>
                <Grid container spacing={theme.spacing(2)}>
                    <Grid item xs={4}>
                        <MetricCard metricValue={USDollarFormatter.format(income ?? 0)} metricName={langpack.incomingrent} icon={<AccountBalanceWalletOutlined sx={{ ...iconSx, background: colors.green, }} />} />
                    </Grid>
                    <Grid item xs={4}>
                        <MetricCard metricValue={USDollarFormatter.format(hoaFees ?? 0)} metricName={langpack.outgoingfees}  icon={<Calculate sx={{ ...iconSx, background: colors.red, }} />} />
                    </Grid>
                    <Grid item xs={4}>
                        <MetricCard metricValue={percentFormatter.format(occupancyRate ?? 0)} metricName={langpack.occupancyrate}icon={<Speed sx={{ ...iconSx, background: colors.orange, }} />} />
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ minHeight: `calc(100vh - 180px - 84px - ${theme.spacing(8)})`, padding: theme.spacing(4), overflow: "auto" }}>
                            <Box display="flex" flexDirection="row" >
                                <Typography fontSize="22px" fontWeight={600} >{langpack.tendayforecast}</Typography>
                                <Box flex={1} />
                                {( calEvents != null && calEvents.length != 0) && <Typography>{calEvents?.length + " " + "event" + ( calEvents?.length == 1 ? "" : "s" ) }</Typography>}
                            </Box>
                            <Box display="flex" flexDirection="column" >
                                { calEvents?.length == null || calEvents.length == 0 ?
                                    <>
                                        <Box component="img" src={calendarIllustration} height="40vh" marginTop={theme.spacing(4)} />
                                        <Typography color={colors.grey} textAlign="center" marginTop={theme.spacing(4)} >{langpack.noeventsplanned}</Typography>
                                    </>
                                    :
                                    <>
                                        {calEvents.map(e => <CalendarEventRow event={e} />)}
                                    </>
                                }
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}