import { LangPack } from './langpack';

// Implement the interface for Chinese
const chinesePack: LangPack = {
  // Nav drawer labels
  account: "账号",
  collapse: "收回",
  dashboard: "主页",  
  myproperties: "我的房产",
  calendar: "日历",
  // Dashboard labels
  incomingrent: "租金收入",
  outgoingfees: "传出费用",
  occupancyrate: "入住率",
  tendayforecast: "十天预报",
  noeventsplanned: "没有事件",
  // Calendar labels
  rentevents: "租房事件",
  hoaevents: "HOA事件",
  noticeevents:"通知事件",
  event: "活动",
  addevent: "添加活动",
  notice: "通知",
  addnotice: "添加通知",
  // Add property labels
  property: "房产",
  properties: "房产",
  downloadpropertydata: "下载房产数据",
  addproperty: "添加房产",
  addnewproperty: "添加新房产",
  save: "保存",
  cancel: "取消",
  addtenant: "添加租户",
  addoccupant: "添加房客",
  nopropertiesyet: "还没有房产",
  leasepdf: "租约 PDF",
  propertyinfo: "房产信息",
  propertyinfo_address: "地址",
  propertyinfo_owner: "业主",
  lease: "租约",
  tenants: "租户",
  occupants: "房客",
  utilities: "水电费",
  autopayenabled: "自动付款设置?",
  utility_electricity: "电力",
  utility_water: "水",
  utility_gas: "煤气",
  utility_trash: "垃圾",
  hoa: "HOA",
  note: "注释",
  company: "公司",
  phone: "电话",
  website: "网站",
  association: "HOA",
  monthlyfee: "月费",
  annualfee: "年费",
  editnote: "注释",
  hoainfo: "HOA 信息",
  // Test Labels
  test: "测试",
};

export default chinesePack;