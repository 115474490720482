import HouseGif from "../images/loading.gif";
import { Box, Typography } from "@mui/material";

export function Loading(){
    const quips = [
        "Polishing the doorknobs, just a sec...",
        "Planting flowers in the front yard...",
        "Tightening the faucets. Hang tight!",
        "Hanging up the welcome mat...",
        "Arranging the furniture, almost done...",
        "Fluffing the pillows for your comfort...",
        "Turning the lights on, be right there...",
        "Sweeping the chimney, won't be long...",
        "Watering the lawn, just a moment..."
    ];

    return (
        <Box sx={{margin: "15 auto", textAlign: "center"}}>
            <Box component="img" src={HouseGif} sx={{marginTop: "10vh"}} />
            <Typography variant="h5" color="GrayText">{ quips[Math.floor(Math.random() * quips.length)] }</Typography>
        </Box>
    );
}