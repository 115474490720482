import { useMsal } from "@azure/msal-react";
import { ReactNode, useEffect, useState } from "react"
import { Loading } from "./components/Loading";
import { Navigate } from "react-router-dom";
import { READ_SCOPE } from "./lib/scopes";

interface Props {
    children: ReactNode
  }

export function SubscriptionValidator({children}: Props){
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const [paid, setPaid] = useState<boolean | null>(null);
    
    async function FetchPaid(){
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });
        // console.log("accessTokenClaims:");
        // console.log(accessToken.idTokenClaims);
        // @ts-ignore
        setPaid(accessToken.idTokenClaims.subscriptionStatus === "paid" || accessToken.idTokenClaims.subscriptionStatus === "perProperty");
    }

    useEffect(() => {
        if( paid === null ) FetchPaid();
    }, [paid]);

    return (
        <>
            {paid === null ? <Loading/> : ( paid ? children : <Navigate to="/checkout" /> ) }
        </>
    );
}